$(document).ready(function () {
    $("#topBtn").hide();　//ボタンを非表示にする
    $(window).on("scroll", function () {
        if ($(this).scrollTop() > 500) { //ページの上から500pxスクロールした時
            $("#topBtn").fadeIn("fast"); //ボタンがフェードインする
        } else {
            $("#topBtn").fadeOut("fast");　//ボタンがフェードアウトする
        }
        scrollHeight = $(document).height(); //ドキュメントの高さ
        scrollPosition = $(window).height() + $(window).scrollTop(); //現在地
        footHeight = $(".footMain").innerHeight(); //止めたい位置の高さ(今回はfooterSub)
        if (scrollHeight - scrollPosition <= footHeight) { //ドキュメントの高さと現在地の差がfooterSubの高さ以下の時
            $("#topBtn").css({
                "position": "absolute", //pisitionをabsoluteに変更
                "bottom": footHeight + 25,
            });
        } else { //それ以外の場合は
            $("#topBtn").css({
                "position": "fixed", //固定表示
                "bottom": "10px"
            });
        }
    });

    //スムーススクロール設定
    $('#topBtn').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);　//スムーススクロールの速度
        return false;
    });
});
